<template>
  <v-app-bar
    app
    color='white'
    flat
  >
    <v-container class='py-0 fill-height'>
      <v-img
        contain
        class='mr-10'
        src='images/voice-tv-h.svg'
        max-height='40'
      ></v-img>

      <v-btn
        v-for='link in links'
        :key='link.name'
        :to='link.name'
        text
      >
        {{ link.title }}
      </v-btn>
      <v-btn
        text
        key='logout'
        @click='logout'
      >
        Logout
      </v-btn>
      <v-spacer></v-spacer>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    links: [
      // { title: 'Dashboard', name: 'Dashboard' },
      { title: 'Live TV', name: 'LiveTV' },
      { title: 'Special Live', name: 'SpecialLive' },
      { title: 'Special Videos', name: 'SpecialVideo' },
    ],
  }),
  methods: {
    logout() {
      this.$store.dispatch('userSignOut')
    },
  },
}
</script>
